  <template>
    <main class="home">
   
      <!-- HEADER TOP MENU BAR -->
        <Header></Header>
      <!-- <Header :currentLanguage="currentLanguage"  :navbar="bannerData.navs"></Header> -->
      <!-- Rest of the HOME PAGE STARTS -->
        <div class="LandingPage">
          <!-- SECTION 1 -->  
          <section  v-for="(data,index) in homePageContentData" :key="index" :class="data.templateType.slug+'BackImage'" > 
            <b-container>
              <Sections :sectionData="data" :sectionType="data.templateType.slug" />
            </b-container>
          </section>
          </div>
           <Questionnaire :data="populateFormData" />
      <!-- footer section -->
            <section class="footer">
              <footer>
                <cookie-law theme="dark-lime"></cookie-law>
              </footer>
              <Footer/>
            </section>
    </main>
</template>

<script>
import Header from "../components/Header";
import Footer from "../components/Footer";
import Sections from "../components/Section";
import Questionnaire from "../components/Project/Questionnaire";
const _ = require('lodash');
import { mapGetters } from "vuex";
import {FlamelinkPlugin} from "@/services/FlamelinkSettings";
export default {
  name: "ContactUs",
 
  data(){
    return{
      dataReady:false,
      mydata:[],
      feedback: {
        title: 'Need to contact the team?',
        text: 'Please fill out the form below to submit your enquiry to the team or alternatively please write to Rob Morgan, Atkins, 12 Orchard St, Swansea West Glamorgan, SA1 5AD.',
        commentBoxes: [
           
            { optionDetail: "Your enquiry or comments*", placeholder: 'Enter your enquiry' }
        ],
        dataProtection: [

            'Your comments will be recorded within the communications record that we hold for the Project and any issues raised will be considered by relevant members of our Team. The privacy and security of your personal data is a priority to Atkins. If you wish to understand how we handle your data you may refer to our privacy notice. ',
            'Atkins wishes to use the contact details provided to keep you updated on the Newgale Coastal Adaptation Scheme, including details of ways in which you can become involved in influencing the project through discussion groups, consultation activities and events. If you do not wish to hear from us in future, then please check the box and we will not contact you again. You can opt in again at any time.'
        ],
        submitBtn: 'Submit',
        nameAddress: {
            fname: {
                label: 'First name',
                placeholder: 'please enter your first name'
            },
            lname: {
                label: 'Last name',
                placeholder: 'please enter your last name'
            },
            number: {
                label: 'Phone number',
                placeholder: 'please enter your phone number'
            },
            email: {
                label: 'Email address',
                placeholder: 'please enter your email address'
            },
            address2: {
                label: 'Company',
                placeholder: 'Enter your company name'
            },
            subject: {
                label: 'Subject',
                placeholder: 'Enter your subject'
            },
            postCode: {
                label: 'Post code',
                placeholder: 'please enter your post code'
            },

        }
      },
    }
  },
  components: {
    Sections,
    Questionnaire,
    Header,
    Footer,
  },
  // async mounted() {
  //    await FlamelinkPlugin.content.get({ schemaKey: 'contactForm' })
  //    .then( (response)=> {
  //       //console.log('RRR',response);
  //         Object.values(response).forEach(val => {
  //           this.mydata.push(val)
  //         });
  // console.log('RRR', this.mydata);
  //       //this.mydata = response;
  //       this.dataReady = true  ;
  //     })
  //     .catch( (error)=> {
  //       console.log(error);
  //     })
  // },
 async mounted() {
    this.scrollToTop()
    await this.$gtag.query('event', 'screen_view', {
        app_name: 'MyApp',
        screen_name: 'Contact Us page',
      })
  },

  methods: { 

      scrollToTop() {
          window.scrollTo({top: 0,left: 0, behavior: 'smooth'});
 
      }
  },
  computed: {
    ...mapGetters(["language", "content"]),
     populateFormData(){
      if (this.content && this.content.cForm) {
        return this.content.cForm[0]
        } else return {};
    },
    homePageContentData() {
        var route=this.$route.path.split('/');
        var newArr=[]; 
        if (this.content && this.content.pageContent) {
            newArr= this.content.pageContent.filter((el)=> {
              if(el.linkedPage.length>0 && el._fl_meta_.status!='review'){
                  return el.linkedPage[0].pageSlug == route[1] ;
              }
                
            });
        return _.orderBy(newArr, 'orderNumber', 'asc');
        } else return {};
    },
   
  
   
    currentLanguage() {
      if (this.language) {
        return this.language;
      } else return "english";
    },
  },
  
};
</script>
