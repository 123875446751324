import emailjs, { init } from 'emailjs-com';
import VueSimpleAlert from "vue-simple-alert";
import Swal from 'sweetalert2'
import index from '../store/index'



class FeedbackService {
    constructor(_feedbackData) {
        init('user_1tTjOBaELSKCj1osqoOQT');
        this.feedbackData = _feedbackData;
    }

    dataExist(){
        var finalData={}
                this.feedbackData.forEach( (data) =>{
                    Object.entries(data).forEach(([key, value]) => {
                        if(key=='nameAddressData'){
                            Object.entries(this.feedbackData[0].nameAddressData).forEach(([key, value]) => {
                                finalData[key]=value
                                })
                        }else{
                            finalData[key]=value
                        }
                    
                    })
                })
          if(finalData["first_name"]!='' && finalData["last_name"]!='' && finalData["email_address"]!='' && finalData["phone_number"]){
              return true
          }else{
              return false
          }
    }

    formatFeedback() {
        //console.log('Testing',this.feedbackData)
        var finalData={}
        this.feedbackData.forEach( (data) =>{
            Object.entries(data).forEach(([key, value]) => {
                if(key=='nameAddressData'){
                    Object.entries(this.feedbackData[0].nameAddressData).forEach(([key, value]) => {
                           finalData[key]=value
                        })
                }else{
                    finalData[key]=value
                }
              
            })
          })
           

        return {
         
            first_name: finalData["first_name"],
            last_name: finalData["last_name"],
            email: finalData["email_address"],
            phone_number: finalData["phone_number"],
            postcode: finalData["postcode"],
            company: finalData["company"],
            subject: finalData["subject"],
            enquiry: finalData["feedBackData"],
            agree_to_record: finalData["dataProtection"],
          
        }
    }

    checkInputsArePresent() {
        let CheckDataExist = this.dataExist()
   

      //  console.log('DDD',CheckDataExist)

 

        if ( CheckDataExist) {

            //console.log('>>Sending Data')
            let sendReadyData = this.formatFeedback();
          //  console.log('>>Debug Data')
           // console.log(sendReadyData);

            let dataName = 'Thank you for your submission! A member of the team will be in touch soon regarding your enquiry.'

            if(index.state.language.name == "welsh")
            {
                dataName = "Diolch am gyflwyno eich cais! Bydd aelod o'r tîm yn cysylltu â chi yn fuan ynglŷn â'ch ymholiad"     
            }



            Swal.fire({
                text:  dataName,
                confirmButtonColor: '#288073',
                confirmButtonText: 'OK',
                width: '500px'
            });

            //EmailJS Login
            //https://dashboard.emailjs.com/admin/templates
            //createdesignbristol@gmail.com
            //Create@123

            emailjs.send("service_4f4puun", "template_sy8vfae", sendReadyData)
                 .then(() => console.log('Email send'))
                .catch(() => console.log('err'));
                this.$gtag.event('ButtonClicked', {
                    'event_category': 'Submit',
                    'event_label': 'Form',
                    'value': 'completed'
                  })
        }
        else {
            //console.log('Data Incomplete')
            VueSimpleAlert.alert('Please complete all feedback required fields before submitting');
            this.$gtag.event('ButtonClicked', {
                'event_category': 'Submit',
                'event_label': 'Form',
                'value': 'data incomplete'
              })
            let titleName = 'Form was unable to be submitted. Please check for errors.'

            if(index.state.language.name == "welsh")
            {
                titleName = "Nid oedd modd cyflwyno'r ffurflen. Cofiwch wirio am unrhyw gamgymeriadau. "      
            }



            Swal.fire({
                text:  titleName,
                confirmButtonColor: '#288073',
                confirmButtonText: 'OK',
                width: '400px'
            });
                
        }
    }
}

export default FeedbackService;

